import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useOpenings, useSimpleToggle } from 'hooks';
import React, { FC, useEffect, useState } from 'react';

import { FilterDropdownButton } from 'components/FilterDropdown/Button';
import { FilterOpen } from 'components/FilterDropdown/FilterOpen';
import { FilterDropdownProps } from 'components/FilterDropdown/index';
import { Filterable, mapFilterable } from 'components/FilterDropdown/util';
import { useDebounce } from 'hooks/useDebounce';

const PER_PAGE = 100;

export const FilterOpeningDropdown: FC<FilterDropdownProps<Filterable>> = ({
  selected,
  setSelected,
  showTitle,
  enterprise,
  userId,
  error,
}) => {
  const { off, showContent: open, toggle: openUp } = useSimpleToggle();
  const [query, setQuery] = useState('');
  const debounceSetQuery = useDebounce(setQuery, 500);
  const { fetchOpenings, openings } = useOpenings();
  const name = 'opening';
  const fetchOpeningsWithArgs = React.useCallback(async () => {
    await fetchOpenings({
      page: 1,
      per_page: PER_PAGE,
      query,
      enterprise,
      user_id: userId,
    });
  }, [enterprise, userId, query, fetchOpenings]);

  useEffect(() => {
    void fetchOpeningsWithArgs();
  }, [fetchOpeningsWithArgs]);

  return (
    <ClickAwayListener onClickAway={off}>
      <div>
        <FilterDropdownButton
          name={name}
          open={open}
          openUp={openUp}
          count={selected.length}
          clearFilter={() => {
            setSelected([]);
          }}
          titles={selected.map(select => select.title)}
          showTitle={showTitle}
          setSelected={setSelected}
          selected={selected}
          error={error}
        />
        {open && (
          <FilterOpen
            name={name}
            selected={selected}
            setSelected={setSelected}
            options={mapFilterable(openings ?? [])}
            setQuery={debounceSetQuery}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};
