import { AgentStatus, ApplyBotTypes, AxAgentValues, Step } from './types';

export const AI_AGENT_SETUP = 'ai_agent_setup';
export const AI_RECOMMENDATIONS = 'ai_recommendations';
export const ALL_BRANDS = 'all_brands';
export const INDIVIDUAL_BRAND = 'individual_brand';

export const steps: Step[] = [
  { number: 1, label: 'Upload knowledge base' },
  { number: 2, label: 'Customize AI agent' },
  { number: 3, label: 'Customize widget appearance' },
];

export const applyBots: { label: string; key: string; description: string }[] =
  [
    {
      label: 'Show jobs by location',
      key: 'apply',
      description:
        'Recommended if more than 20 active openings exist in the account..',
    },
    {
      label: 'Show all available jobs',
      key: 'apply_few',
      description:
        'Recommended if less than 20 active openings exist in the account.',
    },
    {
      label: 'Show only one job',
      key: 'apply_one',
      description:
        'This option is disabled if more than one job is available in the account.',
    },
  ];

export const initialAxAgentValues: AxAgentValues = {
  chatbotName: '',
  contactInfo: '',
  knowledgeBaseUuid: '',
  logoUrl: '',
  primaryColor: '',
  brandName: '',
  brandId: null,
  phoneNumberFallback: '',
  emailFallback: '',
  websiteFallback: '',
  enableInFountainPlatforms: false,
  smsEnabled: false,
  brands: [],
  atsConnectionId: '',
  axAgentId: '',
  isBrandsAvailable: false,
  sampleKnowledgeBaseFileUrl: '',
  knowledgeBaseHelpCenterArticleUrl: '',
  status: 'draft' as AgentStatus,
  totalChatAgents: 0,
  isAllBrandSetup: false,
  customWebsites: [],
  applyBotType: 'apply' as ApplyBotTypes,
  noOfActiveFunnelsVisibleToApplicant: 1,
  careerSiteUrl: '',
  isDuplicateSettingCannotApplyAgain: false,
};
