import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCustomerAttributeEntities } from 'hooks/userCustomerAttributeEntities';
import globalMessages from 'shared/global/messages';

import { RulesProps, SharedConditionProps } from '../../../types';
import messages from './messages';
import { AttributeTypeOption, GroupedOption } from './types';

export const CustomerAttributeTypeSelect: React.VFC<
  SharedConditionProps
> = props => {
  const { condition, ruleId, setRules } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<GroupedOption[]>([]);

  const { result: attributeEntities } = useCustomerAttributeEntities();

  const intl = useIntl();

  useEffect(() => {
    if (attributeEntities.status === 'ready') {
      const groupings = attributeEntities.data;
      const mappedOptions = [];
      if (groupings.LOCATION) {
        mappedOptions.push({
          label: intl.formatMessage(globalMessages.location),
          options: groupings.LOCATION.map(attr => ({
            label: attr.type,
            value: attr.type_id,
            entityClass: attr.entity,
          })),
        });
      }
      if (groupings.LOCATIONGROUP) {
        mappedOptions.push({
          label: intl.formatMessage(globalMessages.locationGroup),
          options: groupings.LOCATIONGROUP.map(attr => ({
            label: attr.type,
            value: attr.type_id,
            entityClass: attr.entity,
          })),
        });
      }
      if (groupings.OPENING) {
        mappedOptions.push({
          label: intl.formatMessage(globalMessages.opening),
          options: groupings.OPENING.map(attr => ({
            label: attr.type,
            value: attr.type_id,
            entityClass: attr.entity,
          })),
        });
      }
      if (groupings.POSITION) {
        mappedOptions.push({
          label: intl.formatMessage(globalMessages.position),
          options: groupings.POSITION.map(attr => ({
            label: attr.type,
            value: attr.type_id,
            entityClass: attr.entity,
          })),
        });
      }

      setOptions(mappedOptions);
      setIsLoading(false);
    }
  }, [attributeEntities, intl]);

  const onChangeCustomerAttributeType = (
    attributeType: AttributeTypeOption,
  ) => {
    if (setRules) {
      setRules(
        produce((draftRules: RulesProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          const customerAttributes =
            draftConditions[idx].extra.customer_attributes ?? {};

          customerAttributes.type_id = attributeType.value;
          customerAttributes.entity_class = attributeType.entityClass;
          draftConditions[idx].extra.customer_attributes = customerAttributes;
        }),
      );
    }
  };

  const selectedAttributeType = options
    .flatMap(opt => opt.options)
    .find(
      opt =>
        opt.entityClass === condition.extra.customer_attributes?.entity_class &&
        opt.value === condition.extra.customer_attributes?.type_id,
    );

  return (
    <Grid item xs={6}>
      <StyledReactSelect
        isLoading={isLoading}
        isDisabled={isLoading}
        options={options}
        value={selectedAttributeType}
        label={intl.formatMessage(messages.customerAttributeType)}
        onChange={onChangeCustomerAttributeType}
        placeholder={intl.formatMessage(messages.search)}
      />
    </Grid>
  );
};
