import { WorkflowReviewStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { StageDetailPatchResponse } from 'containers/WorkflowEditor/contexts/stageContext';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { UpdateStageResultContextProvider } from './context';
import { ReviewQuestionnaire } from './ReviewQuestionnaire';
import { Settings } from './Settings';

export interface ReviewStageProps {
  stage: WorkflowReviewStage;
  updateStageResult: StageDetailPatchResponse;
}

export const ReviewStage: VFC<ReviewStageProps> = ({
  stage,
  updateStageResult,
}) => {
  return (
    <UpdateStageResultContextProvider value={updateStageResult}>
      <div data-testid="ReviewStage">
        <StageSection>
          <Settings stage={stage} />
        </StageSection>
        <StageSection>
          <IdleMoveRule stage={stage} />
        </StageSection>
        <StageSection>
          <ReviewQuestionnaire />
        </StageSection>
      </div>
    </UpdateStageResultContextProvider>
  );
};
