/* eslint-disable camelcase */
import {
  AgentConnectionResponse,
  ChatAgentService,
  CreateAgentParams,
  User,
} from 'api-clients/monolith';

import {
  AgentStatus,
  ApplyBotTypes,
  AxAgentResponse,
  AxThreadResponse,
  KnowledgeBaseResponse,
  UploadFaqResponse,
} from './types';

const getBaseUrl = (): string => {
  const url = localStorage.getItem('chatAgentBackendUrl');
  if (!url) {
    throw new Error('Base URL not found in localStorage');
  }
  return url;
};

async function fetchJson<T>(url: RequestInfo, data?: RequestInit): Promise<T> {
  const response = await fetch(url, data);
  if (!response.ok) {
    throw response;
  }
  return response.json() as Promise<T>;
}

export const fetchAccessToken = async (
  accountSlug: string,
): Promise<{
  access_token: string;
  chat_agent_connection_url: string;
  chat_agent_widget_url: string;
}> => {
  const response =
    await ChatAgentService.getInternalApiAgentIntegrationsAgentFetchAccessToken(
      accountSlug,
    );

  return response as {
    access_token: string;
    chat_agent_connection_url: string;
    chat_agent_widget_url: string;
  };
};

export const createAgent = async (
  accountSlug: string,
  name: string,
  emailFallback: string,
  phoneNumberFallback: string,
  websiteFallback: string,
  knowledgeBaseId: string,
  enableInFountainPlatforms: boolean,
  smsEnabled: boolean,
  customWebsites: string[] | null,
  careerSiteUrl: string | null,
): Promise<{ agent_id: string }> => {
  const response = await ChatAgentService.postInternalApiAgentIntegrationsAgent(
    accountSlug,
    {
      name,
      email_fallback: emailFallback,
      phone_fallback: phoneNumberFallback,
      website_fallback: websiteFallback,
      knowledge_base_id: knowledgeBaseId,
      enable_in_fountain_platforms: enableInFountainPlatforms,
      sms_to_chat_agent_enabled: smsEnabled,
      allowed_origins: customWebsites,
      career_site_url: careerSiteUrl,
    } as CreateAgentParams,
  );

  return response as { agent_id: string };
};

export const deleteAgent = async (
  accountSlug: string,
  agentId: string,
): Promise<unknown> => {
  const response =
    await ChatAgentService.deleteInternalApiAgentIntegrationsAgent(
      accountSlug,
      agentId,
    );
  return response;
};

export const retrieveAgentConnection = async (
  accountSlug: string,
  agentId: string,
): Promise<AgentConnectionResponse> => {
  const response = await ChatAgentService.getInternalApiAgentIntegrationsAgent(
    accountSlug,
    agentId,
  );
  return response;
};

export const fetchAgentConnections = async (
  accountSlug: string,
): Promise<{
  is_brands_available: boolean;
  is_all_brand_setup: boolean;
  sample_knowledge_base_file_url: string;
  knowledge_base_help_center_article_url: string;
}> => {
  const response = await ChatAgentService.getInternalApiAgentIntegrationsAgent1(
    accountSlug,
  );
  return response;
};

export const PublishChatAgent = async (
  accountSlug: string,
  brandId: string | null,
  enableInFountainPlatforms: boolean,
  smsEnabled: boolean,
  agentId: string,
  status: string,
): Promise<unknown> => {
  const response =
    await ChatAgentService.postInternalApiAgentIntegrationsAgentPublishChatAgent(
      accountSlug,
      brandId as string,
      enableInFountainPlatforms,
      smsEnabled,
      agentId,
      status,
    );
  return response;
};

export const updateAgentConnection = async (
  accountSlug: string,
  agentId: string,
  brandId?: string,
): Promise<AgentConnectionResponse> => {
  const response = await ChatAgentService.putInternalApiAgentIntegrationsAgent(
    accountSlug,
    agentId,
    brandId,
  );
  return response;
};

export const fetchUser = async (
  accountSlug: string,
  userExternalId: string,
): Promise<{ user: User }> => {
  const response =
    await ChatAgentService.getInternalApiAgentIntegrationsAgentUser(
      accountSlug,
      userExternalId,
    );
  return response;
};

export const createKnowledgeBase = async (
  accessToken: string,
): Promise<KnowledgeBaseResponse> => {
  const response = await fetchJson<KnowledgeBaseResponse>(
    `${getBaseUrl()}/api/v1/knowledge-base`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ name: 'knowledge-base' }),
    },
  );
  return response;
};

export const retrieveKnowledgeBase = async (
  accessToken: string,
  knowledgeBaseUuid: string,
): Promise<KnowledgeBaseResponse> => {
  const response = await fetchJson<KnowledgeBaseResponse>(
    `${getBaseUrl()}/api/v1/knowledge-base/${knowledgeBaseUuid}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return response;
};

export const uploadFaq = async (
  accessToken: string,
  knowledgeBaseUuid: string,
  file: File,
  userExternalId: string,
): Promise<UploadFaqResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('createdBy', userExternalId);

  const response = await fetchJson<UploadFaqResponse>(
    `${getBaseUrl()}/api/v1/knowledge-base/${knowledgeBaseUuid}/faq`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    },
  );
  return response;
};

export const downloadFaq = async (
  accessToken: string,
  knowledgeBaseUuid: string,
): Promise<Blob> => {
  const response = await fetch(
    `${getBaseUrl()}/api/v1/knowledge-base/${knowledgeBaseUuid}/faq`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  if (!response.ok) {
    throw new Error('Failed to download FAQ');
  }
  return response.blob();
};

export const listAxAgents = async (
  accessToken: string,
): Promise<AxAgentResponse[]> => {
  const response = await fetchJson<AxAgentResponse[]>(
    `${getBaseUrl()}/api/v1/ax-agent`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return response;
};

export const retrieveAxAgent = async (
  accessToken: string,
  agentId: string,
): Promise<AxAgentResponse> => {
  const response = await fetchJson<AxAgentResponse>(
    `${getBaseUrl()}/api/v1/ax-agent/${agentId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return response;
};

export const updateAxAgent = async (
  accessToken: string,
  axAgentUuid: string,
  data: {
    name?: string;
    hasHumanFallback?: boolean;
    emailFallback?: string;
    phoneNumberFallback?: string;
    websiteFallback?: string;
    knowledgeBaseUuid?: string;
    brand?: string;
    status?: AgentStatus;
    allowedOrigins?: string[] | null;
    applyBotType?: ApplyBotTypes;
    lastModifiedBy?: string;
    careerSiteUrl?: string;
  },
): Promise<AxAgentResponse> => {
  const response = await fetchJson<AxAgentResponse>(
    `${getBaseUrl()}/api/v1/ax-agent/${axAgentUuid}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    },
  );
  return response;
};

export const createAxThread = async (
  axAgentUuid: string,
  data: {
    applicantId: string;
    openingId: string;
    phoneNumber: string;
    email: string;
    bot: string;
    createInitialMessage: boolean;
  },
): Promise<AxThreadResponse> => {
  const response = await fetchJson<AxThreadResponse>(
    `${getBaseUrl()}/api/v1/ax-agent/${axAgentUuid}/thread`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );
  return response;
};

export const updateAtsConnection = async (
  accessToken: string,
  atsConnectionId: string,
  brandName: string,
  brandId: string | null,
  accountSlug: string,
): Promise<unknown> => {
  const response = await fetchJson<AxThreadResponse>(
    `${getBaseUrl()}/api/v1/ats/ats_connection/${atsConnectionId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        name: brandName,
        extra: { brandId, accountSlug },
      }),
    },
  );
  return response;
};
